<template>
  <div>
    <p class="text-2xl">
      Higienizador
    </p>
    <v-card>
      <validation-observer
        ref="formValidator"
        autocomplete="off"
        tag="form"
      >
        <div class="px-2">
          <v-card-text class="pt-5">
            <v-row class="pt-2">
              <v-col
                cols="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Nome da Campanha"
                  rules="required"
                  vid="name"
                >
                  <v-text-field
                    v-model="name"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    label="Nome da Campanha"
                    outlined
                    dense
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="4"
                sm="12"
              >
                <v-card
                  class="mx-auto"
                  outlined
                >
                  <v-card-text>
                    <v-textarea
                      v-model="phonesTextarea"
                      label="Telefones (DDD + 9º dígito + número)"
                      outlined
                      dense
                      hide-details
                      placeholder="11999999991
11999999992
11999999993
11999999994"
                      @input="processFileContent(phonesTextarea)"
                    />
                    <v-divider class="my-3" />
                    <input
                      ref="refInputEl"
                      type="file"
                      accept=".txt"
                      :hidden="true"
                      @change="getFile"
                    />
                    <v-btn
                      class="me-3"
                      color="primary"
                      @click="$refs.refInputEl.click()"
                    >
                      <v-icon class="d-sm-none">
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                      <span class="d-none d-sm-block">Subir Contatos</span>
                    </v-btn>
                    <v-btn
                      color="error"
                      outlined
                      @click="resetPhones"
                    >
                      Resetar
                    </v-btn>
                    <br />
                    <div class="mt-2">
                      Cole a lista de telefones ou selecione um arquivo <code>(.txt)</code>.
                    </div>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <p class="mb-0">
                      Total de números no arquivo: <span class="primary--text">{{ totalPhones }}</span> <br />
                      Total de números duplicados a serem removidos: <span class="primary--text">{{ duplicatePhones }}</span> <br />
                      Total geral de números: <span class="primary--text">{{ phones.length }}</span>
                    </p>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <v-divider />

        <div class="pa-3">
          <v-card-text>
            <v-btn
              color="primary"
              class="me-3 mt-5"
              @click="checkAccountConfirmation = true"
            >
              Salvar
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-5"
              @click="resetForm"
            >
              Limpar
            </v-btn>
          </v-card-text>
        </div>
      </validation-observer>
    </v-card>

    <confirm-account
      v-if="checkAccountConfirmation"
      @accountStatus="checkConfirmedAccount"
      @finish="store"
    />
    <tutorial tutorial-key="TSP3" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { getTotalPhones, groupByArrayAndRemoveDuplicates } from '@/utils/filePhones'
import Tutorial from '@/components/tutorial/index.vue'
import ConfirmAccount from '@/components/confirm-acount/index.vue'

export default {
  components: {
    ConfirmAccount,
    Tutorial,
  },
  setup() {
    const name = ref(null)
    const phones = ref([])
    const phonesTextarea = ref('')
    const duplicatePhones = ref(0)
    const totalPhones = ref(0)

    const checkAccountConfirmation = ref(false)

    return {
      name,
      phones,
      phonesTextarea,
      duplicatePhones,
      totalPhones,
      icons: {
        mdiCloudUploadOutline,
      },
      checkAccountConfirmation,
    }
  },

  methods: {
    ...mapActions('serviceSanitizer', [
      'create',
    ]),

    resetPhones() {
      this.$refs.refInputEl.value = null
      this.duplicatePhones = 0
      this.totalPhones = 0
      this.phones = []
    },

    resetForm() {
      this.name = null
      this.phonesTextarea = ''
      this.resetPhones()
    },

    async processFileContent(rows) {
      const totalPhones = getTotalPhones(rows)
      const phones = groupByArrayAndRemoveDuplicates(totalPhones)
      const duplicatePhones = totalPhones.length - phones.length

      this.phones = phones
      this.duplicatePhones = duplicatePhones
      this.totalPhones = totalPhones.length
    },

    async getFile(e) {
      this.phones = []

      if (!e.target.files || (e.target.files && !e.target.files[0])) {
        this.$notify(
          'Arquivo não importado.',
          'error',
        )

        return
      }

      const file = e.target.files[0]

      if (file.type !== 'text/plain') {
        this.$notify(
          'Selecione um arquivo texto (.txt).',
          'error',
        )

        return
      }

      const reader = new FileReader()
      reader.onload = e => this.processFileContent(e.target.result)
      reader.readAsText(file)
    },

    async checkConfirmedAccount(status) {
      if (status === true) {
        await this.store()
      }
    },

    async store() {
      this.checkAccountConfirmation = false

      if (!this.phonesTextarea && this.phones.length === 0) {
        this.$notify(
          'Informe uma lista de números ou selecione um arquivo (.txt).',
          'error',
        )

        return
      }

      if (await this.$refs.formValidator.validate()) {
        const status = await this.create({
          name: this.name,
          serviceId: 4,
          sendDate: new Date(),
          phones: this.phones,
        })

        if (status) {
          this.$router.push('/report/service')
          this.resetForm()
        }
      }
    },
  },
}
</script>

<style lang="css">
.desc {
  font-size: 14px;
  color: #6c757d;
}
</style>
